<template>
  <div class="section mb-5">

    <head-slot>
      <title>Scoring And Performance | Manage Teams</title>
    </head-slot>

    <div class="section-header">

      <h1>Scoring and Performance</h1>

      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active">
          <a href="#" @click.prevent="">Scoring and Performance</a>
        </div>
        <div class="breadcrumb-item">Manage Teams</div>

      </div>

    </div>

    <div class="section-body">

      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <team-form @show="show" :team="team"/>
        </div>
        <div class="col-12 col-md-6 col-lg-6">

          <team-table :teams="teams" @show="show" @setTeam="setTeam" @searchTeam="searchTeam"
                      :loading-teams="loadingTeam"/>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TeamForm from '@/components/scoringAndPerformance/manageTeams/teams/Form';
import TeamTable from '@/components/scoringAndPerformance/manageTeams/teams/Table';
import {searchTeam} from '@/services/manageTeams/Teams';
import {useToast} from "vue-toastification";

export default {
  components: {
    TeamForm,
    TeamTable
  },
  data() {
    return {
      teams: [],
      team: {},
      loadingTeam: true,
      toast: useToast(),
    }
  },
  methods: {
    show(teams) {
      this.loadingTeam = false;
      this.teams = teams;
    },

    searchTeam(seek) {
      this.loadingTeam = true;
      this.toast.info('Searching')
      searchTeam(seek).then(response => {
        this.loadingTeam = false;
        this.toast.clear();
        this.teams.data = response.data;
      }, error => {
        this.loadingTeam = false;
        this.toast.clear();
        this.toast.error(error.response.data.message);
      });

    },

    setTeam(team) {

      this.team.id = team.id;
      this.team.name = team.name;
      this.team.team_type_name = team.team_type_name;
      this.team.entity_name = team.entity_name;
      this.team.abbreviation = team.abbreviation;
      this.team.team_type_id = team.team_type_id;
      this.team.entity_id = team.entity_id;
      this.team.logo = team.logo;
      this.team.remark = team.remark;


    }
  },

}
</script>
<style>
@media only screen and (min-width: 600px) {
  .new-team, .teams-table {
    height: 590px !important;
    overflow: auto;
  }

}
</style>