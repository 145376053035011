<template>
  <div class="card new-team">
    <div class="card-header">
      <h4>New Team</h4>

    </div>
    <div class="card-body">

      <form action="#" method="post" enctype="multipart/form-data" @submit.prevent="store" id="teamForm">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">

              <label>Name</label>
              <input type="text" class="form-control" name="name" v-model="teamData.name">
              <input type="hidden" class="form-control" name="team_id" v-if="teamData.id" v-model="teamData.id">
              <span class="text text-danger" v-if="validationErrors.name">{{ validationErrors.name[0] }}</span>

            </div>

          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Abbreviation</label>
              <input type="text" class="form-control" name="abbreviation" v-model="teamData.abbreviation">
              <span class="text text-danger" v-if="validationErrors.abbreviation">{{
                  validationErrors.abbreviation[0]
                }}</span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group" style="position: relative">
              <label>Type</label>

              <input type="text" class="form-control" v-model="teamData.team_type_name" list="type_id_input"
                     name="type_id_input" @input="type_id = setTypeID(type,types)" autocomplete="off"
                     v-if="teamData.id"
              >
              <input type="text" class="form-control" v-model="type" list="type_id_input"
                     name="type_id_input" @input="type_id = setTypeID(type,types)" autocomplete="off"
                     v-else
              >

              <input type="hidden" name="type_id" v-model="teamData.team_type_id" v-if="teamData.id">
              <input type="hidden" name="type_id" v-model="type_id" v-else>


              <datalist v-if="findListItem(types, type).length > 0" id="type_id_input">

                <option v-for="item in findListItem(types, type)" :key="item.id" :value="item.name"/>

              </datalist>
              <span class="text text-danger" v-if="validationErrors.type_id">{{ validationErrors.type_id[0] }}</span>

              <!--            <auto-suggest :showList="showList" :listData="types" :seek="type" :inputID="'type_id_input'" @setItem="setItem" />-->


            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Entity/ Country</label>
              <input type="text" class="form-control" v-model="teamData.entity_name" list="entity_id_input"
                     name="entity_id_input" @input=" entity_id = setEntityID(teamData.entity_name,entities)"
                     autocomplete="off"
                     v-if="teamData.id"
              >

              <input type="text" class="form-control" v-model="entity" list="entity_id_input"
                     name="entity_id_input" @input=" entity_id = setEntityID(entity,entities)" autocomplete="off"
                     v-else
              >

              <input type="hidden" name="entity_id" v-model="teamData.entity_id" v-if="teamData.id">
              <input type="hidden" name="entity_id" v-model="entity_id" v-else>


              <datalist v-if="findListItem(entities, entity).length > 0" id="entity_id_input">

                <option v-for="item in findListItem(entities, entity)" :key="item.id" :value="item.name"/>

              </datalist>
              <span class="text text-danger" v-if="validationErrors.entity_id">{{
                  validationErrors.entity_id[0]
                }}</span>

            </div>
          </div>
          <div class="col-12 col-md-6" v-if="teamData.id">
            <img :src="$store.state.Global.rootCDN + teamData.logo" alt="" style="height: 70px;">
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Logo</label>
              <input type="file" class="form-control" name="logo">
              <span class="text text-danger" v-if="validationErrors.logo">{{ validationErrors.logo[0] }}</span>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label>Remark</label>
              <textarea class="form-control" name="remark" v-model="teamData.remark"></textarea>
              <span class="text text-danger" v-if="validationErrors.remark">{{ validationErrors.remark[0] }}</span>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group float-right">
              <button type="submit" class="btn btn-primary">Save</button>
              <button type="reset" class="btn btn-danger ml-1" @click="resetForm">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>
<script>
// import $ from 'jquery'

import {getEntities, getTypes, show, store, update} from "@/services/manageTeams/Teams";
import {searchItemInObject, setItemOfHiddenFieldIfExistsInOptions} from '@/services/LocalSearch'
import {useToast} from "vue-toastification";


export default {
  // components: {
  //   AutoSuggest
  // },
  props: ['team'],

  data() {
    return {
      teamData: this.team,
      types: [],
      type: '',
      type_id: '',
      entities: [],
      entity: '',
      entity_id: '',
      showList: false,
      toast: useToast(),
      validationErrors: [],
      teams: [],

    }
  },

  methods: {

    findListItem(listData, seek) {

      return searchItemInObject(listData, seek)

    },
    loadList() {

      this.showList = this.type.length > 0;

    },

    getTypes() {

      getTypes().then(response => {
        this.types = response.data;
      });
    },

    getEntities() {
      getEntities().then(response => {
        this.entities = response.data;
      })
    },

    setTypeID(value, types) {


      let options = []
      if (document.getElementById("type_id_input")) {
        options = document.getElementById("type_id_input").options;
      }

      return setItemOfHiddenFieldIfExistsInOptions(value, options, types)


    },

    setEntityID(value, entities) {

      let options = []
      if (document.getElementById("entity_id_input")) {
        options = document.getElementById("entity_id_input").options;
      }


      return setItemOfHiddenFieldIfExistsInOptions(value, options, entities)


    },

    setError(error) {

      this.toast.clear();

      if (error.response.status === 422) {

        this.validationErrors = error.response.data.errors;

        this.toast.error(error.response.data.message);

      } else {

        this.toast.error(error.response.data.message)
      }
    },

    show(page = 1) {
      show(page).then(response => {

        this.$emit('show', response.data);

      }, error => {
        this.setError(error);
      });
    },

    store() {
      this.toast.info('processing...')
      let form = document.getElementById('teamForm')
      let data = new FormData(form);

      if (!this.teamData.id) {
        store(data).then(() => {
          this.show();
          this.toast.clear();
          this.validationErrors = [];
          this.resetForm();
        }, error => {

          this.setError(error);

        })
      } else {
        update(data).then(() => {
          this.show();
          this.toast.clear();
          this.validationErrors = [];
          this.resetForm();
        }, error => {
          this.setError(error);
        });
      }


    },

    resetForm() {

      this.teamData.id = false;
      this.teamData.name = '';
      this.teamData.abbreviation = '';
      this.teamData.team_type_id = '';
      this.teamData.team_type_name = '';
      this.teamData.entity_name = '';
      this.teamData.entity_id = '';
      this.teamData.logo = '';
      this.teamData.remark = '';
      this.entity = '';
      this.type = '';
    },

  },


  mounted() {
    this.getTypes();
    this.getEntities();
    this.show();

  }
}


</script>
<style scoped>


</style>