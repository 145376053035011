import API from '../API';

export function getTypes() {
    return API.get('/lookup/team-types/show');
}

export function getEntities() {
    return API.get('/lookup/entities/show');
}

export function store(data) {
    return API.post('/manage-teams/teams/store', data)
}

export function update(data) {
    return API.post('/manage-teams/teams/update', data)
}

export function show(page, active = 0, id = 0, tournament_id = 0) {

    return API.post('/manage-teams/teams/show', {page, active, id, tournament_id});

}

export function activeInActive(data) {
    return API.post('/manage-teams/teams/active-inactive', data);
}

export function destroy(data) {
    return API.post('/manage-teams/teams/destroy', data);
}

export function searchTeam(seek) {
    return API.post('/manage-teams/teams/search', {'seek': seek});
}