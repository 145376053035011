<template>
  <div class="card teams-table" style="overflow-x: hidden">
    <div class="card-header">
      <h4>Teams</h4>

      <div class="card-header-form">

        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search" v-model="seek" @keydown.enter="search">
          <div class="input-group-btn">
            <button class="btn btn-primary" @click="search"><i class="fas fa-search"></i></button>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body p-0">

      <app-loader v-if="loadingTeams" class="mt-2"/>

      <div class="row" v-else>
        <div class="col-12">
          <div class="table-responsive">
            <table class=" table table-striped table-md">
              <tbody>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Status</th>
                <th class="text-center">Action</th>
              </tr>
              <tr v-for="(team, index) in teams.data" :key="team.id">
                <td>{{ team.id }}</td>
                <td>{{ team.name }}</td>
                <td>
                  <div class="badge badge-success" @click="activeInactive(team.id, team.active, index)"
                       :class="{'badge-success': team.active === 1, 'badge-danger': team.active !== 1}">
                    <span v-if="team.active === 1">Active</span>
                    <span v-else>Inactive</span>
                  </div>
                </td>
                <td class="text-center">
                  <a href="#" class="btn btn-primary btn-sm mr-1" @click.prevent="setTeam(index)">Detail</a>

                  <a href="#" class="btn btn-danger btn-sm" @click.prevent="destroy(team.id)">Remove</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
    <div class="card-footer text-right">
      Showing {{ teams.to }} from {{ teams.total }} Records
      <nav class="d-inline-block">
        <ul class="pagination mb-0">
          <li class="page-item" :class="{'disabled': teams.current_page === 1}">
            <a class="page-link" href="#" tabindex="-1"
               @click.prevent="show(teams.current_page -1)">
              <i class="fas fa-chevron-left"></i>
            </a>
          </li>

          <li class="page-item" :class="{'disabled': teams.current_page === teams.last_page}">
            <a class="page-link" href="#" @click.prevent="show(teams.current_page + 1)"><i
                class="fas fa-chevron-right"></i></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import {activeInActive, destroy, show} from "@/services/manageTeams/Teams";
import {useToast} from "vue-toastification";
import Loader from "@/components/layouts/parts/Loader";

export default {
  props: ['teams', 'loadingTeams'],
  data() {
    return {
      toast: useToast(),
      team: {},
      seek: '',
    }
  },
  components: {
    appLoader: Loader,
  },
  methods: {
    activeInactive(id, active) {

      let data = {
        id: id,
        active: active
      }


      this.toast.info('processing...')

      activeInActive(data).then(() => {

        this.toast.clear();

        let active = 0;

        if (active === 1)
          active = 0

        else
          active = 1;

        this.show(this.teams.current_page);

      });

    },
    show(id) {
      show(id).then(response => {
        this.$emit('show', response.data);
      });
    },

    destroy(id) {

      let data = {
        'id': id,
      };

      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: 'Yes, delete it!'

      }).then((result) => {

        if (result.value) {

          this.toast.info('deleting record!');
          destroy(data).then(() => {

            this.toast.clear();
            this.$swal(
                'Deleted!',

                'Record has been deleted.',

                'success'
            );

            this.show(this.teams.current_page);

          }, error => {
            this.toast.clear();

            this.toast.error(error.response.data.message)
          });


        }

      });


    },

    setTeam(index) {

      this.$emit('setTeam', this.teams.data[index]);
    },

    search() {
      this.$emit('searchTeam', this.seek);
    }
  }
}
</script>